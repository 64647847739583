import React from "react";
import { Footer as AntdFooter } from "antd/es/layout/layout";
const Footer = () => {
  return (
    <React.Fragment>
      <AntdFooter className="pt-1"
       style={{
        background: "#212121",
       }}
      >
        <div className="text-center p-0 text-white d-flex align-items-center justify-content-center">
            <p className="">© Mekathlon 2024. All Rights Reserved</p>

        </div>
      </AntdFooter>
    </React.Fragment>
  );
};

export default Footer;
