/** @format */

import {
  DashboardOutlined,
  PayCircleOutlined,
  ScheduleOutlined,
  VerifiedOutlined,
} from "@ant-design/icons";

export const menu = [
  {
    key: "1",
    icon: <DashboardOutlined style={{ color: "white" }} />,
    label: "Dashboard",
    route: "/",
  },
  {
    key: "2",
    icon: <VerifiedOutlined style={{ color: "white" }} />,
    label: "City Hackathon",
    route: "/city-hackathon",
  },
  {
    key: "3",
    icon: <ScheduleOutlined style={{ color: "white" }} />,
    label: "Slot Mechanism",
    route: "/slot-mechanism",
  },
  {
    key: "4",
    icon: <PayCircleOutlined style={{ color: "white" }} />,
    label: "Payments",
    route: "/payment",
  },
];
