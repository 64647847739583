import React, { Component, Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Spin } from "antd";
import PublicRoutes from "../App/utils/publicRoutes";
import DashboardLayout from "../App/Components/layout/DashboardLayout";
import LoginPage from "../App/Pages/Login/index";
import PrivateRoute from "../App/utils/privateRoute";

const AllRoutes = () => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  const dashboardRoutes = [
    {
      path: "/",
      exact: true,
      Component: lazy(() => import("../App/Pages/Dashboard/Dashboard")),
    },
    {
      path: "/dashboard",
      exact: true,
      Component: lazy(() => import("../App/Pages/Dashboard/Dashboard")),
    },
    {
      path: "/city-hackathon",
      exact: true,
      Component: lazy(() => import("../App/Pages/CityHackathon/index")),
    },
    {
      path: "/slot-mechanism",
      exact: true,
      Component: lazy(() => import("../App/Pages/SlotMechanism/index")),
    },
    {
      path: "/payment",
      exact: true,
      Component: lazy(() => import("../App/Pages/Payments/Payments")),
    },
    // {
    //   path: "/login",
    //   exact: true,
    //   Component: lazy(() => import("../App/Pages/Login/index"))
    // }
  ];

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);
  let dashboardLinks = dashboardRoutes?.map((each) => each?.path);

  return (
    <>
      {/* <Routes>
   
      </Routes> */}
      <DashboardLayout
        visible={dashboardLinks?.includes(window.location.pathname)}
      >
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route excat path="login" element={<LoginPage />} />
          </Route>
          <Route>
            {dashboardRoutes.map(({ path, Component, exact }, index) => (
              <Route
                path={path}
                key={path}
                exact={exact}
                element={
                  <Suspense fallback={<Spin />}>
                    <PrivateRoute>
                    <Component />
                    </PrivateRoute>
                  </Suspense>
                }
              />
            ))}
          </Route>
        </Routes>
      </DashboardLayout>
    </>
  );
};

export default AllRoutes;
