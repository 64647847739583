import React, { useState } from "react";
import { Layout, theme } from "antd";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Footer from "./Footer";

const { Content } = Layout;
const DashboardLayout = ({ children, visible }) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return visible ? (
    <Layout
      style={{
        minHeight: "100vh",
        background: "linear-gradient(50deg, #48e28b, #4a8564)",
      }}
    >
      <Sidebar
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        colorBgContainer={colorBgContainer}
      />
      <Layout
        style={{
          background: "transparent", // Keep inner layout transparent
        }}
      >
        <Topbar
          colorBgContainer={colorBgContainer}
          setCollapsed={setCollapsed}
          collapsed={collapsed}
        />
        <div
          style={{
            margin: "24px 16px",
            // padding: "24px",
            background: "linear-gradient(70deg, #48e28b, #4a8564)", // Gradient only in padding area
            borderRadius: "8px",
          }}
        >
          <Content
            style={{
              minHeight: 450,
              padding: "24px",
                             // Additional padding if needed for inner content
              background: "#212121", // Solid color for the content itself
              borderRadius: "8px", // Inner content with rounded corners for aesthetics
            }}
          >
            {children}
          </Content>
        </div>
        <Footer />
      </Layout>
    </Layout>
  ) : (
    children
  );
};

export default DashboardLayout;
