import axios from "axios";
import AllRoutes from "./AllRoutes/AllRoutes";
import { AuthProvider } from "./context/auth-context";
import { BASE_URL } from "./App/utils/apiConfig";


function App() {

  const token = (localStorage.getItem("access"));
 


  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.common["Authorization"] = token
  ? `Bearer ${token}`
  : "";

  return (
    <AuthProvider>
   <AllRoutes />
    </AuthProvider>
  );
}

export default App;
