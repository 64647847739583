import { message } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({children}) => {
    const isLoggedIn = localStorage.getItem("access") !== null;

    const navigate = useNavigate()

    useEffect(() => {
        if (!isLoggedIn) {
            message.error("Please Login First")
            navigate("/login")
        }
    }, [isLoggedIn, navigate])
 
    return isLoggedIn ? children : null;
};

export default PrivateRoute;