// LoginPage.js
import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/auth-context';
import axios from 'axios';

const LoginPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { login } = useAuth();

  const onFinish = async (values) => {
    try {
      const response = await axios.post('admin-dashboard/login/', values, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const { data } = response;
      localStorage.setItem('access', data.access);

      login(data.access);
      message.success('Login successful');
      navigate('/'); 
    } catch (error) {
      if (error.response) {
        message.error(`Login failed: ${error.response.data.message || 'Please try again.'}`);
      } else if (error.request) {
        message.error('No response received from server. Please try again.');
      } else {
        message.error('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div style={{ maxWidth: 300, margin: '0 auto', marginTop: 100 }}>
      <h1>Login</h1>
      <Form
        form={form}
        name="login"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email!' },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;